// vue babel plugin doesn't support pragma replacement
import { mdx } from '@mdx-js/vue'
let h;
/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4 id="table-of-contents">Table of contents:</h4>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#caching-strategies"
        }}>{`Caching strategies`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#conclusion"
        }}>{`Conclusion`}</a></li>
    </ol>
    <h2 id="introduction">
  Introduction
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`When it comes to debates on how the cache should be handled on the Web? or what is best caching strategy ?
A caching strategy is common way or a pattern if you prefer for answering to a common problem such as: What
should we do just right after receiving a response from a server? Should we keep it cached in memory
or should we do something else?`}</p>
    <p>{`Different ideas and opinions can be based in general on what people has discovered in their experiences,
while investigating the subject or when they were directly trying deal with it.`}</p>
    <p>{`On my side I like to think that, it's always a good idea to check by curiosity, how big techs companies
are solving these common problems and issues. If a pattern is already existing and used somewhere by large
companies, the same pattern could fit or not to your current use case. Why reinventing the wheel ?`}</p>
    <p>{`The thing is that for handling cache issues, there is no magical recipe and even big techs companies
are constantly improving themselves in order to provide a coherent cache solution to their users.`}</p>
    <p>{`In this post, I will try to bring you more visibility, on how a service worker in your application
will help you to have more control on the user experience you want to offer to your users or customers.`}</p>
    <p>{`If you are landing by mistake on this post and you don't know what is a service worker, you will find
the big picture and some interesting use cases in my other post: `}<strong parentName="p">{`Progressive Web Applications in 2021`}</strong></p>
    <h2 id="caching-strategies">
  Caching strategies
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Since developers were trying to solve some common use cases when they were dealing with the cache,
some bright people from Google decided to put on the table a set of libraries answering to most
frequent situations that developers will have to take care about in their web applications
and more particularly in PWA.`}</p>
    <p>{`The project is called `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/tools/workbox"
      }}>{`Workbox`}</a>{` and inside it you'll find some caching strategies based on most
common pattern. These caching strategies are called workbox strategies and they are providing most
common caching strategies to use in an easy and nice way.`}</p>
    <h3>{`Stale While Revalidate`}</h3>
    <p>{`This strategy is focused on providing the quickest answer to your users by trying to throw what you
have potentially put yourself in the cache. If no cache, it will fallback to the network and then
you will have something to put in the cache that you can use the next time as described above.`}</p>
    <p>{`Important detail on this strategy is that regardless of the age of the cached response, the cache
is going to be revalidate each time after each request in order to keep it fresh for the next user.`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/stale-while-revalidate.png')} loading="lazy" alt="stale while revalidate" description="diagram of stale while revalidate flow" />
    <hr></hr>
    <h3>{`Cache First`}</h3>
    <p>{`A bit simpler than the previous one, if there is something in the cache, it will be used and no network
call will be happening in order to update the cache. Of course, if there is nothing cached a network call
will occur in order to have something ready on the next call.`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/cache-first.png')} loading="lazy" alt="cache first diagram flow" description="diagram of cache first flow" />
    <hr></hr>
    <h3>{`Network Only`}</h3>
    <p>{`Quite easy to guess, you are relying each time on the network because you need to show something specific
to your users that can't be cached. So if you are working on such use cache, this standard strategy is your way to go.`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/network-only.png')} loading="lazy" alt="network only diagram flow" description="diagram of network only flow" />
    <hr></hr>
    <h3>{`Cache Only`}</h3>
    <p>{`This strategy is not suitable for most frequent applications workflow or scenario. Everything that you
will provide to your users will be picked from the cache which must pre-filled before in a pre-caching step.`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/cache-only.png')} loading="lazy" alt="cache only diagram flow" description="diagram of cache only flow" />
    <hr></hr>
    <h2 id="conclusion">
  Conclusion
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`You will have the opportunity to define or customise your own strategy by for example extending one
of the existing strategies with specific steps that suits the user experience you are working on.`}</p>
    <p>{`An interesting strategy that could be built to reproduce a well know one `}<strong parentName="p">{`not provided in workbox strategies
is cache network race strategy`}</strong></p>
    <h3>{`Cache Network Race`}</h3>
    <p>{`According to some applications or services the user device has to run + the performance of the device hardware,
a network request in some case might be the quickest thing. Keeping in mind that we will potentially grab data
that is already cached in the device.`}</p>
    <img style={{
      width: '100%'
    }} src={require('@/assets/img/post/cache_network_race_condition.png')} loading="lazy" alt="Cache & network race diagram flow" description="diagram of Cache & network race flow" />
    <p>{`These wonderful ready to use caching strategies are among a lot of others set of libraries provided by Workbox.
Here is the link again just in case you forgot to click on it previously. `}<a parentName="p" {...{
        "href": "https://developers.google.com/web/tools/workbox"
      }}>{`Workbox project`}</a></p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

export default {
  name: 'Mdx',
  inject: {
    $mdxComponents: {
      default: () => () => ({})
    }
  },
  computed: {
    components() {
      return this.$mdxComponents()
    }
  },
  render(createElement) {
    h = mdx.bind({ createElement, components: this.components })
    return MDXContent({ components: this.components })
  }
}
   